<template>
    <ContentCharacter class="market-place">
        <template slot="content">
            <Loader v-if="isLoadingData"/>

            <div v-else class="minting-nft">
                <div class="minting-nft-header">
                    <slot name="header" />
                </div>

                <div class="minting-nft__content">
                    <div
                        data-aos="fade-right"
                        data-aos-delay="300"
                        class="minting-nft__content-box minting-nft__content-box--card"
                    >
                        <card-wrapper
                            @click="$emit('click')"
                            :image="character.image"
                            :web-images="character.web_images"
                            :type="character.type"
							:tier="character.tier"
                            :isBackground="false"
                            :statistic="statistic"
                            full-size
                            image-center
                            class="minting-nft__avatar"
                        />
                    </div>

                    <div
                        data-aos="fade-right"
                        data-aos-delay="300"
                        class="minting-nft__content-box minting-nft__content-box--main"
                    >
                        <div class="minting-nft__info">
                            <h2 class="minting-nft__info-title">
                                {{ character.name }}
                            </h2>

                            <div
                                v-if="GET_IS_MOBILE"
                                v-popover.bottom="{ name: 'fighter-video' }"
                                class="minting-nft__info-video"
                            >
                                <BaseIcon class="minting-nft__info-play ic_play" name="ic_play"/>
                                <span>Fighter video</span>
                            </div>

                            <div
                                v-else
                                class="minting-nft__info-video"
                            >
                                <BaseIcon v-popover.bottom="{ name: 'fighter-video' }" class="minting-nft__info-play ic_play" name="ic_play"/>
                                <span>Fighter video</span>
                            </div>

                            <VideoPopover
                                name="fighter-video"
                                :src="character.video"
                                :preview="character.image"
                            />

                            <div v-if="isLevel" class="minting-nft__level">
                                <character-level
                                    :requires="character.experience.requires"
                                    :level="character.level"
                                    :current="character.experience.current"
                                />
                            </div>

                            <div class="minting-nft__characteristics">
                                <Characteristic
                                    v-for="(attribute, i) in character.stats"
                                    :key="i"
                                    v-bind="attribute"
                                />
                            </div>

                            <div class="minting-nft__info-text">
                                {{ character.description }}
                            </div>
                        </div>

                        <slot name="actions" />

                        <superpowers :character="character.superpowers" />
                    </div>

                    <div
                        data-aos="fade-left"
                        :data-aos-delay="GET_IS_MOBILE ? 0 : 600"
                        class="minting-nft__content-box minting-nft__content-box--secondary"
                    >
                        <div class="minting-nft__powers">
                            <h3 class="minting-nft__powers-title">Your skills</h3>

                            <div class="minting-nft__powers-content">
                                <div class="minting-nft__powers-box">
                                    <div class="minting-nft__powers-label">Active</div>

                                    <div class="minting-nft__powers-items">
                                        <div class="minting-nft__powers-item">
                                            <template v-if="character.skills?.active">
                                                <skill
                                                    v-bind="character.skills?.active"
                                                    active
                                                    type="active"
                                                />
                                            </template>

                                            <template v-else>
                                                <skill v-popover="{ name: 'triangle_power-1' }" type="active"/>
                                            </template>
                                        </div>
                                    </div>
                                </div>

                                <div class="minting-nft__powers-box">
                                    <div class="minting-nft__powers-label">Passive</div>
                                    <div class="minting-nft__powers-items">
                                        <div class="minting-nft__powers-item">
                                            <template v-if="character.skills?.passive1">
                                                <skill
                                                    v-bind="character.skills?.passive1"
                                                    active
                                                    type="passive"
                                                />
                                            </template>

                                            <template v-else>
                                                <skill v-popover="{ name: 'triangle_power-2' }" type="passive"/>
                                            </template>
                                        </div>
                                        <div class="minting-nft__powers-item">
                                            <template v-if="character.skills?.passive2">
                                                <skill
                                                    v-bind="character.skills?.passive2"
                                                    active
                                                    type="passive"
                                                />
                                            </template>

                                            <template v-else>
                                                <skill v-popover="{ name: 'triangle_power-2' }" type="passive"/>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="!haveAnySkills"
                                class="minting-nft__powers-ps"
                            >You will be able to buy skills after mint</div>

                            <div
                                v-if="GET_IS_MOBILE"
                                v-popover.bottom="{ name: 'super-kick-video' }"
                                class="minting-nft__powers-video"
                            >
                                <BaseIcon class="minting-nft__powers-play ic_play" name="ic_play" />
                                <span>Watch super kick</span>
                            </div>
                            <div
                                v-else
                                class="minting-nft__powers-video"
                            >
                                <BaseIcon v-popover.bottom="{ name: 'super-kick-video' }" class="minting-nft__powers-play ic_play" name="ic_play" />
                                <span>Watch super kick</span>
                            </div>

                            <VideoPopover
                                name="super-kick-video"
                                :src="character.super_kick_video"
                                :preview="character.image"
                            />

                            <div v-if="isColored" class="colors">
                                <entity-color
                                    v-for="(style, key) in character.style"
                                    :key="key"
                                    class="colors-item"
                                    :title="key"
                                    :text="style.name"
                                    :color="style.value"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </ContentCharacter>
</template>

<script>
import ContentCharacter from '@/components/common/content-character.vue'
import Characteristic from '@/components/common/characteristic.vue'
import Superpowers from '@/components/popovers/superpowers.vue'
import CardWrapper from '@/components/common/card-wrapper.vue'
import Skill from '@/components/common/skill.vue'
import EntityColor from '@/components/common/entity-color.vue'
import CharacterLevel from '@/components/common/character-level.vue'
import VideoPopover from '@/components/popovers/video-popover.vue'
import Loader from '@/components/common/loader.vue'
import {mapGetters} from "vuex";
import Tiers from "@/constants/Tiers";

export default {
    name: "DefaultCharacter",
    components: {
        ContentCharacter,
        Characteristic,
        CardWrapper,
        Superpowers,
        Skill,
        Loader,
        EntityColor,
        CharacterLevel,
        VideoPopover,
    },
    props: {
        character: { type: Object, default: ()=>{} },
        isLoadingData: { type: Boolean, default: true },
        isColored: {type: Boolean, default: false},
        isLevel: {type: Boolean, default: false},
    },
    computed: {
        ...mapGetters(['GET_IS_MOBILE']),

        statistic() {
            return [
                { title: 'Tier', value: Tiers[1] },
            ]
        },
        haveAnySkills() {
            return this.character.skills?.passive1
                || this.character.skills?.passive2
                || this.character.skills?.active;
        },
    },
}
</script>

<style lang="scss" scoped>
.minting-nft {
    color: white;

    &-header {
        min-height: 52px;
    }
}

.minting-nft__content {
    padding: 2em 0;
    margin: 0;
    display: grid;
    grid-template-columns: 385px 500px 438px;
    justify-content: space-between;
    grid-gap: 4em;
}

.minting-nft__content-box {
    &--card {
        padding: 0 1em;

        @media screen and (max-width: 1250px) {
            padding: 0;
        }
    }
}

.minting-nft__avatar {
    width: auto;
}

.minting-nft__info {
    &-video {
		font: 700 16px Raleway;
		color: #BFBFBF;
        margin-top: 1em;
        display: flex;
        align-items: center;
        gap: 1em;
    }
    &-play {
        cursor:pointer;
    }
}

.minting-nft__info-title {
    font: 800 56px Raleway;
    text-transform: uppercase;
    font-style: italic;
}
.minting-nft__info-text {
    white-space: pre-line;
    margin-top: 15px;
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.01em;
}

.minting-nft__powers {
    margin-top: 1em;

    &-content {
        display: flex;
        gap: 3em;
    }
    &-title {
        text-transform: uppercase;
        font: 800 24px Raleway;
    }
    &-label {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 14px;
    }
    &-ps {
        margin-top: 18px;
        color: #BFBFBF;
        font-size: 16px;
        line-height: 1.5em;
    }
    &-video {
		font: 700 20px Raleway;
		font-style: italic;
		color: #BFBFBF;
        margin-top: 1em;
        display: flex;
        align-items: center;
        gap: 1em;
    }
    &-play {
        cursor:pointer;
    }
}

.minting-nft__powers-box {
    margin-top: 20px;

    &:last-child {
        margin-right: 0;
    }
}

.minting-nft__powers-items {
    display: flex;
}
.minting-nft__powers-item {
    margin-right: 22px;

    &:last-child {
        margin-right: 0;
    }
}

.minting-nft__characteristics {
    width: 100%;
    padding-top: 1.5em;
}

.minting-nft__video {
    margin-top: 35px;
}

@media screen and (max-width: 1250px) {
    .minting-nft__content-box--card {
        grid-area: 1 / 1 / 2 / 2;
    }
    .minting-nft__content-box--main {
        grid-area: 1 / 2 / 2 / 3;
    }
    .minting-nft__content-box--secondary {
        grid-area: 2 / 1 / 3 / 3;
    }
    .minting-nft__content {
        grid-template-columns: repeat(2, 1fr);
    }

    .minting-nft__info {
        padding: 0;
    }

    .minting-nft__video {
        margin-top: 65px;
    }

    .minting-nft__powers {
        margin-top: 3em;
        justify-content: center;
    }
}
@media screen and (max-width: 760px) {
    .minting-nft__content {
        grid-template-columns: 1fr;
    }

    .minting-nft__content-box {
        grid-area: auto;
    }

    .minting-nft__powers {
        &-title,
        &-ps {
            text-align: center;
        }
        &-content,
        &-video {
            justify-content: center;
        }
    }

    .minting-nft__info-title {
        margin-top: 0.5em;
        text-align: center;
    }
    .minting-nft__info-video {
        justify-content: center;
    }
}

@media screen and (max-width: $bp_mb) {
    .minting-nft__top {
        flex-direction: column;
    }

    .minting-nft__top-actions {
        margin-top: 20px;
    }

    .minting-nft__content {
        border: none;
        grid-gap: 1em;
    }

    .minting-nft__video {
        margin-left: -15px;
        margin-right: -15px;
    }
}

.colors {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1em;
    column-gap: 2em;
    margin-top: 31px;
    justify-content: space-between;

    @media screen and (max-width: $bp_mb_xs) {
        justify-content: center;
    }
}
.colors-item {
    margin-bottom: 5px;
    min-width: 200px;
    &:last-child {
        margin-bottom: 0;
    }
}

</style>
