var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ContentCharacter",
    { staticClass: "market-place" },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isLoadingData
            ? _c("Loader")
            : _c("div", { staticClass: "minting-nft" }, [
                _c(
                  "div",
                  { staticClass: "minting-nft-header" },
                  [_vm._t("header")],
                  2
                ),
                _c("div", { staticClass: "minting-nft__content" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "minting-nft__content-box minting-nft__content-box--card",
                      attrs: {
                        "data-aos": "fade-right",
                        "data-aos-delay": "300",
                      },
                    },
                    [
                      _c("card-wrapper", {
                        staticClass: "minting-nft__avatar",
                        attrs: {
                          image: _vm.character.image,
                          "web-images": _vm.character.web_images,
                          type: _vm.character.type,
                          tier: _vm.character.tier,
                          isBackground: false,
                          statistic: _vm.statistic,
                          "full-size": "",
                          "image-center": "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("click")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "minting-nft__content-box minting-nft__content-box--main",
                      attrs: {
                        "data-aos": "fade-right",
                        "data-aos-delay": "300",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "minting-nft__info" },
                        [
                          _c("h2", { staticClass: "minting-nft__info-title" }, [
                            _vm._v(" " + _vm._s(_vm.character.name) + " "),
                          ]),
                          _vm.GET_IS_MOBILE
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "popover",
                                      rawName: "v-popover.bottom",
                                      value: { name: "fighter-video" },
                                      expression: "{ name: 'fighter-video' }",
                                      modifiers: { bottom: true },
                                    },
                                  ],
                                  staticClass: "minting-nft__info-video",
                                },
                                [
                                  _c("BaseIcon", {
                                    staticClass:
                                      "minting-nft__info-play ic_play",
                                    attrs: { name: "ic_play" },
                                  }),
                                  _c("span", [_vm._v("Fighter video")]),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "minting-nft__info-video" },
                                [
                                  _c("BaseIcon", {
                                    directives: [
                                      {
                                        name: "popover",
                                        rawName: "v-popover.bottom",
                                        value: { name: "fighter-video" },
                                        expression: "{ name: 'fighter-video' }",
                                        modifiers: { bottom: true },
                                      },
                                    ],
                                    staticClass:
                                      "minting-nft__info-play ic_play",
                                    attrs: { name: "ic_play" },
                                  }),
                                  _c("span", [_vm._v("Fighter video")]),
                                ],
                                1
                              ),
                          _c("VideoPopover", {
                            attrs: {
                              name: "fighter-video",
                              src: _vm.character.video,
                              preview: _vm.character.image,
                            },
                          }),
                          _vm.isLevel
                            ? _c(
                                "div",
                                { staticClass: "minting-nft__level" },
                                [
                                  _c("character-level", {
                                    attrs: {
                                      requires:
                                        _vm.character.experience.requires,
                                      level: _vm.character.level,
                                      current: _vm.character.experience.current,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "minting-nft__characteristics" },
                            _vm._l(
                              _vm.character.stats,
                              function (attribute, i) {
                                return _c(
                                  "Characteristic",
                                  _vm._b(
                                    { key: i },
                                    "Characteristic",
                                    attribute,
                                    false
                                  )
                                )
                              }
                            ),
                            1
                          ),
                          _c("div", { staticClass: "minting-nft__info-text" }, [
                            _vm._v(
                              " " + _vm._s(_vm.character.description) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._t("actions"),
                      _c("superpowers", {
                        attrs: { character: _vm.character.superpowers },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "minting-nft__content-box minting-nft__content-box--secondary",
                      attrs: {
                        "data-aos": "fade-left",
                        "data-aos-delay": _vm.GET_IS_MOBILE ? 0 : 600,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "minting-nft__powers" },
                        [
                          _c(
                            "h3",
                            { staticClass: "minting-nft__powers-title" },
                            [_vm._v("Your skills")]
                          ),
                          _c(
                            "div",
                            { staticClass: "minting-nft__powers-content" },
                            [
                              _c(
                                "div",
                                { staticClass: "minting-nft__powers-box" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "minting-nft__powers-label",
                                    },
                                    [_vm._v("Active")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "minting-nft__powers-items",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "minting-nft__powers-item",
                                        },
                                        [
                                          _vm.character.skills?.active
                                            ? [
                                                _c(
                                                  "skill",
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        active: "",
                                                        type: "active",
                                                      },
                                                    },
                                                    "skill",
                                                    _vm.character.skills
                                                      ?.active,
                                                    false
                                                  )
                                                ),
                                              ]
                                            : [
                                                _c("skill", {
                                                  directives: [
                                                    {
                                                      name: "popover",
                                                      rawName: "v-popover",
                                                      value: {
                                                        name: "triangle_power-1",
                                                      },
                                                      expression:
                                                        "{ name: 'triangle_power-1' }",
                                                    },
                                                  ],
                                                  attrs: { type: "active" },
                                                }),
                                              ],
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "minting-nft__powers-box" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "minting-nft__powers-label",
                                    },
                                    [_vm._v("Passive")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "minting-nft__powers-items",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "minting-nft__powers-item",
                                        },
                                        [
                                          _vm.character.skills?.passive1
                                            ? [
                                                _c(
                                                  "skill",
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        active: "",
                                                        type: "passive",
                                                      },
                                                    },
                                                    "skill",
                                                    _vm.character.skills
                                                      ?.passive1,
                                                    false
                                                  )
                                                ),
                                              ]
                                            : [
                                                _c("skill", {
                                                  directives: [
                                                    {
                                                      name: "popover",
                                                      rawName: "v-popover",
                                                      value: {
                                                        name: "triangle_power-2",
                                                      },
                                                      expression:
                                                        "{ name: 'triangle_power-2' }",
                                                    },
                                                  ],
                                                  attrs: { type: "passive" },
                                                }),
                                              ],
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "minting-nft__powers-item",
                                        },
                                        [
                                          _vm.character.skills?.passive2
                                            ? [
                                                _c(
                                                  "skill",
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        active: "",
                                                        type: "passive",
                                                      },
                                                    },
                                                    "skill",
                                                    _vm.character.skills
                                                      ?.passive2,
                                                    false
                                                  )
                                                ),
                                              ]
                                            : [
                                                _c("skill", {
                                                  directives: [
                                                    {
                                                      name: "popover",
                                                      rawName: "v-popover",
                                                      value: {
                                                        name: "triangle_power-2",
                                                      },
                                                      expression:
                                                        "{ name: 'triangle_power-2' }",
                                                    },
                                                  ],
                                                  attrs: { type: "passive" },
                                                }),
                                              ],
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          !_vm.haveAnySkills
                            ? _c(
                                "div",
                                { staticClass: "minting-nft__powers-ps" },
                                [
                                  _vm._v(
                                    "You will be able to buy skills after mint"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.GET_IS_MOBILE
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "popover",
                                      rawName: "v-popover.bottom",
                                      value: { name: "super-kick-video" },
                                      expression:
                                        "{ name: 'super-kick-video' }",
                                      modifiers: { bottom: true },
                                    },
                                  ],
                                  staticClass: "minting-nft__powers-video",
                                },
                                [
                                  _c("BaseIcon", {
                                    staticClass:
                                      "minting-nft__powers-play ic_play",
                                    attrs: { name: "ic_play" },
                                  }),
                                  _c("span", [_vm._v("Watch super kick")]),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "minting-nft__powers-video" },
                                [
                                  _c("BaseIcon", {
                                    directives: [
                                      {
                                        name: "popover",
                                        rawName: "v-popover.bottom",
                                        value: { name: "super-kick-video" },
                                        expression:
                                          "{ name: 'super-kick-video' }",
                                        modifiers: { bottom: true },
                                      },
                                    ],
                                    staticClass:
                                      "minting-nft__powers-play ic_play",
                                    attrs: { name: "ic_play" },
                                  }),
                                  _c("span", [_vm._v("Watch super kick")]),
                                ],
                                1
                              ),
                          _c("VideoPopover", {
                            attrs: {
                              name: "super-kick-video",
                              src: _vm.character.super_kick_video,
                              preview: _vm.character.image,
                            },
                          }),
                          _vm.isColored
                            ? _c(
                                "div",
                                { staticClass: "colors" },
                                _vm._l(
                                  _vm.character.style,
                                  function (style, key) {
                                    return _c("entity-color", {
                                      key: key,
                                      staticClass: "colors-item",
                                      attrs: {
                                        title: key,
                                        text: style.name,
                                        color: style.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }